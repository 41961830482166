<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Zahlungsmethoden, Versand & Rückgabe
            </div>

            <div class="subtitle-1 font-weight-light">
              Einstellungen für {{ policyPosition }}, ID: {{ editedItemId }}
            </div>
          </template>
          <template>
            <v-container
              class="px-0"
              fluid
            >
              <v-switch
                v-model="useGlobalPolicies"
                :label="`Globale Werte nutzen: ${useGlobalPolicies.toString()}`"
              />
            </v-container>
          </template>
          <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>
          <v-form v-show="!useGlobalPolicies">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <p>Payment Profiles ({{ paymentProfileRadios || 'null' }}) (gespeichert: {{ ebaypaymentid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="paymentProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in paymentProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('payment', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>

                      <!-- <v-radio
                        v-for="(l, i) in paymentProfile"
                        :key="i"
                        :value="l.profileId"
                      >
                        <template slot="label">
                          {{ l.profileName }} ({{ l.profileId }})/n
                          <i>{{ l.profileDesc }}</i>
                        </template>
                        <template slot="label">
                          <i>xxxxx</i>
                        </template>
                      </v-radio> -->
                    </v-radio-group>
                  </v-container>
                </v-col>

                <v-col
                  cols="12"
                >
                  <p>Shipping Policy Profile ({{ shippingPolicyProfileRadios || 'null' }}) (gespeichert: {{ ebayshippingid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="shippingPolicyProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in shippingPolicyProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('shipping', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>
                    </v-radio-group>
                  </v-container>
                </v-col>

                <v-col
                  cols="12"
                >
                  <p>Return Policy Profile ({{ returnPolicyProfileRadios || 'null' }}) (gespeichert: {{ ebayreturnid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="returnPolicyProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in returnPolicyProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('return', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>
                    </v-radio-group>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-col
            cols="12"
            class="text-right"
          >
            <!-- <v-btn
              :disabled="((!paymentProfileRadios || !shippingPolicyProfileRadios || !returnPolicyProfileRadios) && (!useGlobalPolicies))"
              color="success"
              class="mr-0"
              @click="savePaymentShippingReturnPolicy"
            >
              Speichern - Einstellungen für {{ policyPosition }}, ID: {{ editedItemId }}
            </v-btn> -->
          </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  // getEbayUserPaymentShippingReturnPolicy.php
  // https://www.bizpolicy.ebay.de/businesspolicy/payment?pageNumber=1&profileId=45016029014&totalPages=1&source=manage
  // payment, shipping, return
  import { globalVars } from '@/globalVars.js'
  export default {

    name: 'EbayPoliciesComponent',

    mixins: [Proxyable],

    props: {
      policyPosition: {
        type: String,
        required: true,
        default: 'category',
      },
    },

    data: function () {
      return {
        swApiUrl: globalVars.swApiUrl,
        ebayApiUrl: globalVars.ebayApiUrl,
        ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
        apiurl: '',
        apiuser: '',
        apikey: '',
        sandbox: globalVars.sandbox,
        ebayauthtoken: '',
        ebayglobaltemplate: '',
        ebaypaymentid: '',
        ebayshippingid: '',
        ebayreturnid: '',
        email: '',
        apiQuery: {},
        userData: {},
        paymentProfileRadios: '',
        shippingPolicyProfileRadios: '',
        returnPolicyProfileRadios: '',
        // avatarLogo: '',
        // item: 1,
        // items: [
        //   { profileName: 'Real-Time', profileId: 'mdi-clock' },
        //   { profileName: 'Audience', profileId: 'mdi-account' },
        //   { profileName: 'Conversions', profileId: 'mdi-flag' },
        // ],
        paymentProfile: [],
        returnPolicyProfile: [],
        shippingPolicyProfile: [],
        useGlobalPolicies: '',
        overlay: true,
        editedItemId: '',
      }
    },

    computed: {
      ...mapState(['barColor', 'barImage', 'loginStatus', 'swApiQuery']),
      swApiQuery: {
        get () {
          return this.$store.state.swApiQuery
        },
        set (val) {
          this.$store.commit('SET_SW_API_QUERY', val)
        },
      },
      // computedItems () {
      //   return this.items.map(this.mapItem)
      // },
    },

    watch: {
      useGlobalPolicies (val) {
        // called whenever switch1 changes
        console.log('useGlobalPolicies:', val)
        if (val === true) {
          this.paymentProfileRadios = ''
          this.shippingPolicyProfileRadios = ''
          this.returnPolicyProfileRadios = ''
        }
      },
    },

    created () {
      // this.getEbayUserPaymentShippingReturnPolicy()
      // this.getUserProfile()
    },
    updated () {
      // this.getEbayUserPaymentShippingReturnPolicy()
      console.log('Component has been updated!')
      console.log('updateID:', this.editedItemId)
    },
    mounted: function () {
      console.log('mounted - PROP:', this.policyPosition)
    },

    methods: {

      ...mapMutations({
        setLoginStatus: 'SET_LOGIN_STATUS',
        setSwApiQuery: 'SET_SW_API_QUERY',
      }),

      openWindowEbayPaymentShippingReturnPolicy: function (policy, profileId) {
        window.open(this.ebayBusinesspolicyUrl + policy + '?profileId=' + profileId)
      },

      setOverlayTrue () {
        this.overlay = true
      },

      savePaymentShippingReturnPolicy () {
        console.log('savePaymentShippingReturnPolicy', this.paymentProfileRadios)
        this.$http.put(this.swApiUrl + '/sw-api/putEbayPolicies.php', JSON.stringify({
          ebayauthtoken: this.ebayauthtoken,
          ebayglobaltemplate: this.ebayglobaltemplate,
          paymentProfileRadios: this.paymentProfileRadios,
          shippingPolicyProfileRadios: this.shippingPolicyProfileRadios,
          returnPolicyProfileRadios: this.returnPolicyProfileRadios,
          policyPosition: this.policyPosition,
          editedItemId: this.editedItemId,
        })).then((resp) => {
          // console.log('22222apiQuery:', this.apiQuery)
          // console.log(JSON.stringify(resp.data.data))
          // this.shopArticles = resp.data.data
          console.log('save-putEbayPoliecies.php:', resp.data)
          // this.$parent.fetchShopArticles()
          console.log(this.$parent)
        })
      },

      getEbayUserPaymentShippingReturnPolicy (editedItemId) {
        this.$http.get(this.ebayApiUrl + '/getEbayUserPaymentShippingReturnPolicy.php?policyPosition=' + this.policyPosition + '&editedItemId=' + editedItemId).then((resp) => {
          this.paymentProfile = resp.data.paymentProfile
          this.returnPolicyProfile = resp.data.returnPolicyProfile
          this.shippingPolicyProfile = resp.data.shippingPolicyProfile

          this.paymentProfileRadios = resp.data.ebaypaymentid
          this.shippingPolicyProfileRadios = resp.data.ebayshippingid
          this.returnPolicyProfileRadios = resp.data.ebayreturnid
          if (!this.paymentProfileRadios && !this.shippingPolicyProfileRadios && !this.returnPolicyProfileRadios) {
            this.useGlobalPolicies = true
          } else {
            this.useGlobalPolicies = false
          }
          this.overlay = false
          console.log('getEbayUserPaymentShippingReturnPolicy', editedItemId, resp.data)
        })
      },

      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>

<style scoped>
  .black--text /deep/ label {
      color: #2c2c2c;
  }
  .grey--text /deep/ label {
      color: #dbdada;
  }
</style>

<template>
  <div>
    <v-btn
      dark
      text
      @click="fetchShopArticles()"
    >
      Save
    </v-btn>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    />

    <v-data-table
      v-if="!loadingArticlesTable"
      v-model="selected"
      :headers="headers"
      :items="shopArticles"
      :single-select="singleSelect"
      :search="search"
      sort-by="mainDetail.number"
      show-select
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-switch
            v-model="singleSelect"
            label="Single select"
            class="pa-3"
          />
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialogAddItem"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">Send Item to eBay</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Dessert name"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.number"
                        label="Art.nummer"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.fat"
                        label="Fat (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.carbs"
                        label="Carbs (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.ebayField"
                        label="eBay"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDialogAddItem"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="postAddItem"
                >
                  Send to eBay
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogReviseItem"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">Update Item on eBay</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Dessert name"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.number"
                        label="Art.nummer"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.fat"
                        label="Fat (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.carbs"
                        label="Carbs (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.ebayField"
                        label="eBay"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDialogReviseItem"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="postReviseItem(editedItem)"
                >
                  Send to eBay
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialog"
            max-width="600px"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            eager
          >
            <v-card>
              <v-toolbar
                id="dialog-ebay-v-toolbar"
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Settings</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    dark
                    text
                    @click="close()"
                  >
                    Save
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <template>
                <ebay-categories-component
                  ref="ebayCategories"
                  policy-position="article"
                  :edited-item-id="editedItem.id"
                />
              </template>

              <template>
                <ebay-policies-component
                  ref="ebayPolicies"
                  policy-position="article"
                  :edited-item-id="editedItem.id"
                />
              </template>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.mainDetail.number="{ item }">
        <v-tooltip
          top
        >
          <span
            class="tipsy"
          >{{ item.mainDetail.number }}xx
          </span>
        </v-tooltip>
        <a
          :href="'https://' + $store.state.swApiQuery.apiurl + '/search?sSearch=' + item.mainDetail.number"
          target="_blank"
        >
          <p>
            {{ item.mainDetail.number }}
          </p>
        </a>
      </template>

      <template v-slot:item.ebayPolicies="{ item }">
        <div v-if="item.mainDetail.attribute.ebaypaymentid && item.mainDetail.attribute.ebayshippingid && item.mainDetail.attribute.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>
              Payment Profiles ID: {{ item.mainDetail.attribute.ebaypaymentid }} <br>
              Shipping Policy Profile ID: {{ item.mainDetail.attribute.ebayshippingid }} <br>
              Return Policy Profile ID: {{ item.mainDetail.attribute.ebayreturnid }}
            </span>
          </v-tooltip>
        </div>
        <div v-else-if="!item.mainDetail.attribute.ebaypaymentid && !item.mainDetail.attribute.ebayshippingid && !item.mainDetail.attribute.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="yellow"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>Globale Einstellungen über UserProfil</span>
          </v-tooltip>
        </div>
        <div v-else-if="!item.mainDetail.attribute.ebaypaymentid || !item.mainDetail.attribute.ebayshippingid || !item.mainDetail.attribute.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>
              Payment Profiles ID: {{ item.mainDetail.attribute.ebaypaymentid }} <br>
              Shipping Policy Profile ID: {{ item.mainDetail.attribute.ebayshippingid }} <br>
              Return Policy Profile ID: {{ item.mainDetail.attribute.ebayreturnid }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.ebayField="{ item }">
        <v-tooltip
          v-if="item.mainDetail.attribute.vtoolListedEbayId"
          top
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn
              icon
              :href="ebayItemLink + item.mainDetail.attribute.vtoolListedEbayId"
              target="_blank"
            >
              <v-icon
                color="green lighten-1"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span
            class="tipsy"
          >{{ item.mainDetail.attribute.vtoolListedEbayId }}
          </span>
        </v-tooltip>
        <template v-else>
          <v-icon
            color="grey lighten-1"
          >
            mdi-airbag
          </v-icon>
        </template>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.mainDetail.attribute.vtoolListedEbayId"
          small
          class="mr-2"
          @click="reviseItemOpenDialog(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-else
          small
          class="mr-2"
          @click="addItemOpenDialog(item)"
        >
          mdi-arrow-up-bold
        </v-icon>

        <v-icon
          v-if="item.mainDetail.attribute.vtoolListedEbayId"
          small
          @click="deleteItem(item, item.mainDetail.number)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="preloader"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ preloaderText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { globalVars } from '@/globalVars.js'
  import EbayPoliciesComponent from '../components/core/EbayPoliciesComponent'
  import EbayCategoriesComponent from '../components/core/EbayCategoriesComponent'
  export default {
    components: {
      EbayPoliciesComponent,
      EbayCategoriesComponent,
    },
    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      apiurl: '',
      // apiuser: '',
      // apikey: '',
      sandbox: globalVars.sandbox,
      // apiQuery: {},
      dialogAddItem: false,
      dialogReviseItem: false,
      preloader: true,
      preloaderText: 'Loading...',
      loadingArticlesTable: true,
      singleSelect: false,
      // ebayItemLink: 'https://cgi.sandbox.ebay.com/?ViewItem&item=',
      ebayItemLink: 'https://www.ebay.de/itm/',
      search: '',
      selected: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Art.nummer', value: 'mainDetail.number' },
        // { text: 'Fat (g)', value: (name) => this.compareShopToEbayArticles(name) },
        // { text: 'Fat (g)', value: `${this.test}` },
        { text: 'Stock (main)', value: 'mainDetail.inStock' },
        { text: 'eBay Policies', value: 'ebayPolicies' },
        { text: 'eBay', value: 'ebayField' },
        { text: 'eBay Actions', value: 'actions', sortable: false },
      ],
      shopArticles: {},
      ebayArticles: {},
      editedIndex: -1,
      editedItem: {
        name: '',
        number: 0,
        fat: 0,
        carbs: 0,
        ebayField: 0,
      },
      defaultItem: {
        name: '',
        number: 0,
        fat: 0,
        carbs: 0,
        ebayField: 0,
      },
      descriptionLimit: 160,
      entries: [],
      isLoading: false,
      model: null,
      // search: null,
      dialog: false,
    }),

    computed: {
      // formTitle () {
      //   return this.editedIndex === -1 ? 'New Item' : 'Send Item to eBay'
      // },

      items () {
        return this.entries.map(entry => {
          const CategoryName = entry.CategoryName.length > this.descriptionLimit
            ? entry.CategoryName.slice(0, this.descriptionLimit) + '...'
            : entry.CategoryName

          return Object.assign({}, entry, { CategoryName })
        })
      },
    },

    watch: {
      dialogAddItem (val) {
        val || this.closeDialogAddItem()
      },
      dialogReviseItem (val) {
        val || this.closeDialogReviseItem()
      },
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      // this.getApiData()
      this.initialize()
      // console.log('Created in ShopWare:', this.$store.state.loginStatus)
      console.log('Created in ShopWare:', this.$store.state.swApiQuery)
    },

    mounted: function () {
      this.fetchShopArticles()
      // this.fetchEbayArticles()
    },

    methods: {
      initialize () {
        this.shopArticles = [
          {
            name: 'Loading...',
            number: 159,
            fat: 6.0,
            carbs: 24,
            ebayField: 4.0,
          },
        ]
      },

      editItem (item) {
        this.editedIndex = this.shopArticles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        console.log('itemEdit:', item)
        this.$refs.ebayCategories.setEbayCatValuesNameIdEbayId(item.name, item.id, item.mainDetail.attribute.ebaycategoryid)
        this.$refs.ebayPolicies.setOverlayTrue()
        this.$refs.ebayPolicies.getEbayUserPaymentShippingReturnPolicy(item.id)
      },

      fetchShopArticles: function () {
        // https://dev.to/ljnce/use-axios-api-with-vue-cli-54i2
        // https://restcountries.eu/rest/v1/all
        this.$http.get(this.swApiUrl + '/sw-api/getArticleList.php').then((resp) => {
          this.shopArticles = resp.data.data
          this.loadingArticlesTable = false
          this.preloader = false
        })
      },

      close () {
        this.$refs.ebayCategories.saveEbayCategory()
        this.$refs.ebayPolicies.savePaymentShippingReturnPolicy()
        setTimeout(() => this.fetchShopArticles(), 2000)
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      addItemOpenDialog (item) {
        this.editedIndex = this.shopArticles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogAddItem = true
      },

      reviseItemOpenDialog (item) {
        this.editedIndex = this.shopArticles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogReviseItem = true
      },

      deleteItem (item, ordernumber) {
        this.preloader = true
        this.preloaderText = 'Artikel wird beendet...'
        // const index = this.shopArticles.indexOf(item)
        // var filtered = this.ebayArticles.filter(d => d.SKU === ordernumber)
        var r = confirm('Are you sure you want to delete this item?' + item.mainDetail.attribute.vtoolListedEbayId)

        if (r === true) {
          var ebayItemID = item.mainDetail.attribute.vtoolListedEbayId
          console.log('DELETED', item.mainDetail.attribute.vtoolListedEbayId)
          // this.shopArticles.splice(index, 1)
          this.$http.get(this.ebayApiUrl + '/endItem.php?ItemId=' + ebayItemID + '&swNumber=' + ordernumber).then((resp) => {
            // console.log(JSON.stringify(resp.data))
            // this.ebayArticles = resp.data
            console.log('Article DELETED! ', resp.data)
            this.preloaderText = 'Artikel wurde beendet...'
            // this.fetchEbayArticles()
            this.fetchShopArticles()
            this.loadingArticlesTable = false
            this.preloader = false
          })
        } else {
          console.log('NOT DELETED')
        }
      },

      closeDialogAddItem () {
        this.dialogAddItem = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDialogReviseItem () {
        this.dialogReviseItem = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      postAddItem () {
        this.preloader = true
        this.preloaderText = 'Artikel wird angelegt...'
        if (this.editedIndex > -1) {
          Object.assign(this.shopArticles[this.editedIndex], this.editedItem)
        } else {
          this.shopArticles.push(this.editedItem)
        }
        this.closeDialogAddItem()

        console.log('Senden: ', this.editedItem.mainDetail.number)
        const ordernumber = this.editedItem.mainDetail.number
        this.$http.get(this.ebayApiUrl + '/addOrReviseItemVariants.php?ordernumber=' + ordernumber).then((resp) => {
          // console.log(JSON.stringify(resp.data))
          // this.ebayArticles = resp.data
          console.log('Article added! ', resp.data)
          // this.fetchEbayArticles()
          this.fetchShopArticles()
          this.loadingArticlesTable = false
          this.preloader = false
        })
      },

      postReviseItem (item) {
        this.preloader = true
        this.preloaderText = 'Artikel wird aktualisiert...'
        if (this.editedIndex > -1) {
          Object.assign(this.shopArticles[this.editedIndex], this.editedItem)
        } else {
          this.shopArticles.push(this.editedItem)
        }
        this.closeDialogReviseItem()
        // const reviseItemData = this.compareShopToEbayArticlesData(item.mainDetail.number)
        console.log('reviseItem: ', this.editedIndex, item.mainDetail.attribute.vtoolListedEbayId)
        this.$http.get(this.ebayApiUrl + '/addOrReviseItemVariants.php?ordernumber=' + item.mainDetail.number + '&ItemID=' + item.mainDetail.attribute.vtoolListedEbayId).then((resp) => {
          // console.log(JSON.stringify(resp.data))
          // this.ebayArticles = resp.data
          console.log('Article Updatet! ', resp.data)
          // this.fetchEbayArticles()
          this.fetchShopArticles()
          this.loadingArticlesTable = false
          this.preloader = false
        })
      },

    },
  }
</script>

<style>
  #dialog-ebay-v-toolbar{
    width: 100%;
    position: fixed;
    z-index: 9999;
  }
  .tipsy{
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 0.85em;
      line-height: 1.42857143;
      text-align: left;
      white-space: pre-wrap;
  }
  .tipsy-inner{
      text-align: left;
  }
</style>

<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <template>
          <v-spacer />
          <br><br><br><br>
        </template>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              eBay Kategorie
            </div>
            <div class="subtitle-1 font-weight-light">
              eBay Kategorie für {{ policyPosition }}, ID: {{ editedItemId }} zuweisen
            </div>
          </template>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="swCatName"
                  readonly
                  label="Kategorie Name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="swCatId"
                  readonly
                  label="Kategorie ID"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="ebayCatId"
                  class="inputEbayID"
                  type="number"
                  label="eBay ID"
                  @change="getEbayCategorySpecifics"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-btn
                  :disabled="!ebayCatId"
                  :href="'https://www.ebay.de/b/CatName/' + ebayCatId + '/InternNumber'"
                  target="_blank"
                >
                  <v-icon
                    small
                    color="primery"
                    class="mr-2"
                  >
                    mdi-eye
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-autocomplete
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            color="green"
            hide-no-data
            hide-selected
            item-text="CategoryName"
            item-value="CategoryID"
            label="Public CategoryIDs"
            placeholder="Beginnen Sie mit der Eingabe..."
            prepend-icon="mdi-database-search"
            return-object
          />

          <v-divider />
          <v-expand-transition>
            <v-list
              v-if="model"
            >
              <v-list-item
                v-for="(field, i) in fields"
                :key="i"
              >
                <v-list-item-content>
                  {{ i }}
                  <v-list-item-title v-text="field.value" />
                  <v-list-item-subtitle v-text="field.key" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expand-transition>
          <v-spacer />
          <v-btn
            :disabled="!model"
            color="grey darken-3"
            @click="model = null"
          >
            Clear
            <v-icon right>
              mdi-close-circle
            </v-icon>
          </v-btn>
          <template
            v-if="ebayCatId"
          >
            <p>Category Specifics</p>
            <v-btn
              color="grey darken-3"
              @click="collectSpecifics"
            >
              collectSpecifics
              <v-icon right>
                mdi-eye
              </v-icon>
            </v-btn>
            <v-list>
              <v-list-item
                v-for="(specifics, i) in getEbayCategorySpecificsRecommendations"
                :key="i"
              >
                <v-list-item-content>
                  <v-row>
                    <v-col cols="2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-title
                            v-bind="attrs"
                            dense
                            v-on="on"
                            v-text="specifics.Name"
                          />
                        </template>
                        <span>{{ specifics.HelpText }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="swPropertyAsSpecific[specifics.Name]"
                        dense
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-list-item-subtitle>
                        <v-autocomplete
                          v-model="specificSelectModel[specifics.Name]"
                          :items="swPropertys"
                          :label="specifics.Name + ' ='"
                          clearable
                          single-line
                          outlined
                          return-object
                          dense
                          @change="setTextFieldSpecifics(specifics.Name)"
                        />
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                  <v-divider />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'
  import { globalVars } from '@/globalVars.js'

  export default {
    name: 'EbayCategoriesComponent',

    mixins: [Proxyable],

    props: {
      policyPosition: {
        type: String,
        required: true,
        default: 'category',
      },
    },

    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      apiurl: '',
      apiuser: '',
      apikey: '',
      sandbox: globalVars.sandbox,
      ebayauthtoken: '',
      ebayglobaltemplate: '',
      descriptionLimit: 100,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      swCatName: '',
      swCatId: '',
      ebayCatId: '',
      getEbayCategorySpecificsRecommendations: '',
      swPropertys: [],
      swPropertyAsSpecific: [],
      specificSelectModel: [],
      editedItemId: Number,
      vtoolSettings: '',
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'loginStatus', 'swApiQuery']),
      swApiQuery: {
        get () {
          return this.$store.state.swApiQuery
        },
        set (val) {
          this.$store.commit('SET_SW_API_QUERY', val)
        },
      },

      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          console.log('Auswahl!', this.model[key], ' - ', this.model.CategoryID)
          this.ebayCatId = this.model.CategoryID
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const CategoryName = entry.CategoryName.length > this.descriptionLimit
            ? entry.CategoryName.slice(0, this.descriptionLimit) + '...'
            : entry.CategoryName

          return Object.assign({}, entry, { CategoryName })
        })
      },
    },

    watch: {
      search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        // https://stackoverflow.com/questions/28681875/php-multi-dimensional-array-w-parent-id-to-category-taxonomy-array-ebay-style
        fetch(this.ebayApiUrl + '/getCategories.php')
          .then(res => res.json())
          .then(res => {
            const { count, entries } = res
            this.count = count
            this.entries = entries
            console.log('FromEbay:', res)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
      ebayCatId (val) {
        console.log('WATCH getEbayCategorySpecifics: ', val)
        this.getEbayCategorySpecifics()
      },
    },

    created () {
      // console.log('editedItemId:::', this.editedItemId)
      console.log('policyPosition:::', this.policyPosition)
      // this.getVtoolSettings()
    },

    methods: {

      ...mapMutations({
        setLoginStatus: 'SET_LOGIN_STATUS',
        setSwApiQuery: 'SET_SW_API_QUERY',
      }),

      getEbayCategorySpecifics () {
        console.log('getEbayCategorySpecifics: ', this.ebayCatId)
        this.$http.get(this.swApiUrl + '/getCategorySpecifics.php', {
          params: {
            ebayCatId: this.ebayCatId,
          },
        }).then((resp) => {
          console.log('getEbayCategorySpecifics:', resp.data.Recommendations[0].NameRecommendation)
          this.getEbayCategorySpecificsRecommendations = resp.data.Recommendations[0].NameRecommendation
          const swPropertysArray = resp.data.swPropertys
          // swPropertysArray.forEach(function (entry, ) {
          //   console.log(entry.name)
          //   this.swPropertys += entry.name
          // })
          for (var iswPropertys = 0; iswPropertys < swPropertysArray.length; iswPropertys++) {
            this.swPropertys.push(swPropertysArray[iswPropertys].name)
          }
          this.swPropertys.sort()
          // this.swPropertys = resp.data.swPropertys
          console.log('swPropertys:', resp.data.swPropertys)
          return resp.data
        })
      },

      collectSpecifics () {
        const ebaySpecificsAsModel = this.getEbayCategorySpecificsRecommendations
        var specificsArray = {}
        for (var i = 0; i < ebaySpecificsAsModel.length; i++) {
          var modelName = ebaySpecificsAsModel[i].Name
          specificsArray[modelName] = this.swPropertyAsSpecific[modelName]
        }
        return specificsArray
      },

      setTextFieldSpecifics (modelName) {
        this.swPropertyAsSpecific[modelName] = this.specificSelectModel[modelName]
        console.log('collectSpecifics FUNCTION:', this.collectSpecifics())
      },

      getVtoolSettings (catId) {
        console.log('getVtoolSettings:', catId)
        this.$http.get(this.swApiUrl + '/sw-api/getCategories.php').then((resp) => {
          console.log('getVtoolSettings DATA:', resp.data.data)
          const swCategories = resp.data.data
          var i
          for (i = 0; i < swCategories.length; i++) {
            if (catId === swCategories[i].id) {
              this.vtoolSettings = swCategories[i].vtoolSettings
            }
          }
          const vtoolSettings = this.vtoolSettings.ebaySpecifics
          console.log('const vtoolSettings:', vtoolSettings)
          if (vtoolSettings) {
            Object.keys(vtoolSettings).forEach(key => {
              console.log('vtoolSettings...:', key, vtoolSettings[key])
              this.swPropertyAsSpecific[key] = vtoolSettings[key]
            })
          }
        })
      },

      setEbayCatValuesNameIdEbayId (swName, editedItemId, ebayCatId) {
        this.swCatName = swName
        this.swCatId = editedItemId
        this.ebayCatId = ebayCatId
        console.log('setEditedItemId', swName, editedItemId, ebayCatId)
      },

      saveEbayCategory () {
        const swcategoryid = this.swCatId
        const swcategoryname = this.swCatName
        const ebaycategoryid = this.ebayCatId
        console.log('CATEGORY save in ecatComp: ', this.collectSpecifics(), swcategoryid, swcategoryname, ebaycategoryid)

        this.$http.put(this.swApiUrl + '/sw-api/saveCategoryEbaycategoryid.php', JSON.stringify({
          swcategoryid: swcategoryid,
          swcategoryname: swcategoryname,
          ebaycategoryid: ebaycategoryid,
          policyPosition: this.policyPosition,
          editedItemId: this.editedItemId,
          vtoolSettings: JSON.stringify(this.collectSpecifics()),
          test: '-Größe-',
        })).then((resp) => {
          // console.log('22222apiQuery:', this.apiQuery)
          // console.log(JSON.stringify(resp.data.data))
          // this.shopArticles = resp.data.data
          console.log('save-saveCategoryEbaycategoryid.php:', resp.data)
          // this.$parent.fetchShopArticles()
          // console.log(this.$parent)
        })
      },
    },
  }
</script>

<style>
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
</style>
